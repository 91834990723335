import React, {Component} from 'react';
import SecureLS from 'secure-ls';
import axios from 'axios';
import GLOBAL from '../../../../Global';

export default class WidgetPostArchives extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          informations : [],
        }
      }

    /*componentWillMount(){
        var ls = new SecureLS({encodingType: 'aes'})

        if(ls.get('identity')){
            this.fetchData()
        }
    }*/

    fetchData() {
        var ls = new SecureLS({encodingType: 'aes'})
    
        axios.get(GLOBAL.API_URL + '/api/identity/informations/'+ls.get('identity'),{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ informations: response.data });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }
        
    render(){

        //const { informations } = this.state;

        return(
            <div className="widget">
            <h4 className="widget-title">Post Archives</h4>
            <ul className="nav nav-tabs likes-btn">
            <li className="nav-item"><a className="active" href="#y20" data-toggle="tab">2020</a></li>
            <li className="nav-item"><a href="#y21" data-toggle="tab">2021</a></li>
            </ul>
            <div className="tab-content">
            <div className="tab-pane active fade show " id="y20">
                <ul>
                <li><a href="#rien">Janvier</a></li>
                <li><a href="#rien">Février</a></li>
                <li><a href="#rien">Mars</a></li>
                <li><a href="#rien">Septembre</a></li>
                <li><a href="#rien">Octobre</a></li>
                </ul>
            </div>
            <div className="tab-pane fade" id="y21">
                <div className="tab-pane active fade show " id="y20">
                <ul>
                    <li><a href="#rien">Janvier</a></li>
                </ul>
                </div>
            </div>
            </div>{/* who's following */}
        </div>
        )
    }
}