import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginRegister from './components/LoginRegister';
import Home from './components/Home';
import Page from './components/Page';
import Profil from './components/Profil';
import Post from './components/Post';
import HttpsRedirect from 'react-https-redirect';

import SecureLS from 'secure-ls'

class App extends Component {

  render() {
    var ls = new SecureLS({encodingType: 'aes'})
    return (
      <HttpsRedirect>
        <Router>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/login' component={LoginRegister}/>
                <Route path='/page/:page' component={Page}/>
                <Route path='/profil' user={ls.get('identity')} component={Profil}/>
                <Route path='/profil/:id' component={Profil}/>
                <Route path='/post/:id' component={Post}/>
            </Switch>
        </Router>
      </HttpsRedirect>
    );
  }
}

export default App;
