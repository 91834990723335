import React, { Component, Fragment } from 'react';
import SecureLS from 'secure-ls'
import Topbar from './Site/Index/Topbar'
import ColLeft from './Site/Index/ColLeft'
import ColCenter from './Site/Index/ColCenter'
import ColRight from './Site/Index/ColRight'
import SidePanel from './Site/Index/SidePanel'



export default class Home extends Component{


    logout() {
        this.props.history.push("/logout");
    }

    render() {

        //Redirection en cas de non existence de la local storage identity
        var ls = new SecureLS({encodingType: 'aes'})
        if(!ls.get('identity')){
            this.props.history.push('/login')
        }        

        return(
            <Fragment>
            <title>Bothmod : Le réseau social global</title>
            <link rel="icon" href="images/fav.png" type="image/png" sizes="16x16" /> 
            <link rel="stylesheet" href="css/main.min.css" />
            <link rel="stylesheet" href="css/style.css" />
            <link rel="stylesheet" href="css/color.css" />
            <link rel="stylesheet" href="css/responsive.css" />
            <link rel="stylesheet" href="css/overwrite/home.css" />
            <link rel="stylesheet" href="css/overwrite/landing.css" />
            {/*<div class="se-pre-con"></div>*/}
            <div className="theme-layout">
            <div className="postoverlay" />
            
           <Topbar/>
            <section>
                <div className="gap2 gray-bg">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="row merged20" id="page-contents">
                        <ColLeft />
                        <ColCenter />
                        <ColRight />
                        </div>	
                    </div>
                    </div>
                </div>
                </div>	
            </section>

            </div>
            <SidePanel/>		
        </Fragment>
            );
        }     
}