import React, {Component} from 'react';

export default class Profil extends Component {
    render(){
        return (
        <div className="topbar stick">
            <div className="container text-center">
                <div className="logo">
                    <a title="" href="newsfeed.html">
                        <span className="logo_first">Both</span><span className="logo_second">mod</span>
                    </a>
                </div>

            </div>
        </div>
        )
    }
}