import React, {Component, Fragment} from 'react';
import TopProfilPic from '../Index/topbar/TopProfilPic'

export default class Header extends Component {

    render(){
        return (
            <Fragment>
        
            <div className="responsive-header">
                <div className="mh-head first Sticky">
                <span className="mh-btns-left">
                    <a href="#menu"><i className="fa fa-align-justify" /></a>
                </span>
                <span className="mh-text">
                    <a href="newsfeed.html"><img src="images/logo2.png" alt="" /></a>
                </span>
                <span className="mh-btns-right">
                    <a className="fa fa-sliders" href="/">/</a>
                </span>
                </div>
                <div className="mh-head second">
                <form className="mh-form">
                    <input placeholder="search" />
                    <a href="/" className="fa fa-search">/</a>
                </form>
                </div>
                <nav id="menu" className="res-menu">
                <ul>
                    <li><span>Home</span>
                    <ul>
                        <li><a href="index.html" >Home Social</a></li>
                        <li><a href="index2.html" >Home Social 2</a></li>
                        <li><a href="index-company.html" >Home Company</a></li>
                        <li><a href="landing.html" >Login page</a></li>
                        <li><a href="logout.html">Logout Page</a></li>
                        <li><a href="newsfeed.html">news feed</a></li>
                    </ul>
                    </li>
                    <li><span>Time Line</span>
                    <ul>
                        <li><a href="time-line.html">timeline</a></li>
                        <li><a href="timeline-friends.html" >timeline friends</a></li>
                        <li><a href="timeline-groups.html">timeline groups</a></li>
                        <li><a href="timeline-pages.html">timeline pages</a></li>
                        <li><a href="timeline-photos.html">timeline photos</a></li>
                        <li><a href="timeline-videos.html">timeline videos</a></li>
                        <li><a href="fav-page.html">favourit page</a></li>
                        <li><a href="groups.html">groups page</a></li>
                        <li><a href="page-likers.html">Likes page</a></li>
                        <li><a href="people-nearby.html">people nearby</a></li>
                    </ul>
                    </li>
                    <li><span>Account Setting</span>
                    <ul>
                        <li><a href="create-fav-page.html">create fav page</a></li>
                        <li><a href="edit-account-setting.html">edit account setting</a></li>
                        <li><a href="edit-interest.html">edit-interest</a></li>
                        <li><a href="edit-password.html">edit-password</a></li>
                        <li><a href="edit-profile-basic.html">edit profile basics</a></li>
                        <li><a href="edit-work-eductation.html">edit work educations</a></li>
                        <li><a href="messages.html">message box</a></li>
                        <li><a href="inbox.html">Inbox</a></li>
                        <li><a href="notifications.html">notifications page</a></li>
                    </ul>
                    </li>
                    <li><span>forum</span>
                    <ul>
                        <li><a href="forum.html" >Forum Page</a></li>
                        <li><a href="forums-category.html" >Fourm Category</a></li>
                        <li><a href="forum-open-topic.html" >Forum Open Topic</a></li>
                        <li><a href="forum-create-topic.html" >Forum Create Topic</a></li>
                    </ul>
                    </li>
                    <li><span>Our Shop</span>
                    <ul>
                        <li><a href="shop.html" >Shop Products</a></li>
                        <li><a href="shop-masonry.html" >Shop Masonry Products</a></li>
                        <li><a href="shop-single.html" >Shop Detail Page</a></li>
                        <li><a href="shop-cart.html" >Shop Product Cart</a></li>
                        <li><a href="shop-checkout.html" >Product Checkout</a></li>
                    </ul>
                    </li>
                    <li><span>Our Blog</span>
                    <ul>
                        <li><a href="blog-grid-wo-sidebar.html" >Our Blog</a></li>
                        <li><a href="blog-grid-right-sidebar.html" >Blog with R-Sidebar</a></li>
                        <li><a href="blog-grid-left-sidebar.html" >Blog with L-Sidebar</a></li>
                        <li><a href="blog-masonry.html" >Blog Masonry Style</a></li>
                        <li><a href="blog-list-wo-sidebar.html" >Blog List Style</a></li>
                        <li><a href="blog-list-right-sidebar.html" >Blog List with R-Sidebar</a></li>
                        <li><a href="blog-list-left-sidebar.html" >Blog List with L-Sidebar</a></li>
                        <li><a href="blog-detail.html" >Blog Post Detail</a></li>
                    </ul>
                    </li>
                    <li><span>Portfolio</span>
                    <ul>
                        <li><a href="portfolio-2colm.html" >Portfolio 2col</a></li>
                        <li><a href="portfolio-3colm.html" >Portfolio 3col</a></li>
                        <li><a href="portfolio-4colm.html" >Portfolio 4col</a></li>
                    </ul>
                    </li>
                    <li><span>Support &amp; Help</span>
                    <ul>
                        <li><a href="support-and-help.html" >Support &amp; Help</a></li>
                        <li><a href="support-and-help-detail.html" >Support &amp; Help Detail</a></li>
                        <li><a href="support-and-help-search-result.html" >Support &amp; Help Search Result</a></li>
                    </ul>
                    </li>
                    <li><span>More pages</span>
                    <ul>
                        <li><a href="careers.html" >Careers</a></li>
                        <li><a href="career-detail.html" >Career Detail</a></li>
                        <li><a href="404.html" >404 error page</a></li>
                        <li><a href="404-2.html" >404 Style2</a></li>
                        <li><a href="faq.html" >faq's page</a></li>
                        <li><a href="insights.html" >insights</a></li>
                        <li><a href="knowledge-base.html" >knowledge base</a></li>
                    </ul>
                    </li>
                    <li><a href="about.html" >about</a></li>
                    <li><a href="about-company.html" >About Us2</a></li>
                    <li><a href="contact.html" >contact</a></li>
                    <li><a href="contact-branches.html" >Contact Us2</a></li>
                    <li><a href="widgets.html" >Widgts</a></li>
                </ul>
                </nav>
                <nav id="shoppingbag">
                <div>
                    <div>
                    <form method="post">
                        <div className="setting-row">
                        <span>use night mode</span>
                        <input type="checkbox" id="nightmode" /> 
                        <label htmlFor="nightmode" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>Notifications</span>
                        <input type="checkbox" id="switch2" /> 
                        <label htmlFor="switch2" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>Notification sound</span>
                        <input type="checkbox" id="switch3" /> 
                        <label htmlFor="switch3" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>My profile</span>
                        <input type="checkbox" id="switch4" /> 
                        <label htmlFor="switch4" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>Show profile</span>
                        <input type="checkbox" id="switch5" /> 
                        <label htmlFor="switch5" data-on-label="ON" data-off-label="OFF" />
                        </div>
                    </form>
                    <h4 className="panel-">Account Setting</h4>
                    <form method="post">
                        <div className="setting-row">
                        <span>Sub users</span>
                        <input type="checkbox" id="switch6" /> 
                        <label htmlFor="switch6" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>personal account</span>
                        <input type="checkbox" id="switch7" /> 
                        <label htmlFor="switch7" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>Business account</span>
                        <input type="checkbox" id="switch8" /> 
                        <label htmlFor="switch8" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>Show me online</span>
                        <input type="checkbox" id="switch9" /> 
                        <label htmlFor="switch9" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>Delete history</span>
                        <input type="checkbox" id="switch10" /> 
                        <label htmlFor="switch10" data-on-label="ON" data-off-label="OFF" />
                        </div>
                        <div className="setting-row">
                        <span>Expose author name</span>
                        <input type="checkbox" id="switch11" /> 
                        <label htmlFor="switch11" data-on-label="ON" data-off-label="OFF" />
                        </div>
                    </form>
                    </div>
                </div>
                </nav>
            </div>

            <div className="topbar stick">
                <div className="top-area">
                <div className="top-search">
                    <a  href="newsfeed.html">
                    <img src="images/logo-bm-50.png" alt='BOTHMOD' />
                    </a>
                    <form method="post">
                    <input type="text" placeholder="Search..." />
                    <button data-ripple><i className="ti-search" /></button>
                    </form>
                    <div className="user-img">
                        <TopProfilPic/>
                    <span className="status f-online" />
                    <div className="user-setting">
                        <a href="/"><span className="status f-online" />online</a>
                        <a href="/"><span className="status f-away" />away</a>
                        <a href="/"><span className="status f-off" />offline</a>
                        <a href="/"><i className="ti-user" /> view profile</a>
                        <a href="/"><i className="ti-pencil-alt" />edit profile</a>
                        <a href="/"><i className="ti-target" />activity log</a>
                        <a href="/"><i className="ti-settings" />account setting</a>
                        <a href="#" ><i className="ti-power-off" />log out</a>
                    </div>
                    </div>
                </div>
                <span className="ti-menu main-menu" data-ripple />
                </div>
            </div>
            </Fragment>
            )
    }
}