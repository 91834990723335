
import React, {Component} from 'react';

export default class Bottom extends Component {
    render(){
        return(
            <div className="bottombar">
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                <span className="copyright">Sign Up Services About Developers Careers Terms Helps Cookies Contact Ads © Bothmod 2020. All rights reserved.</span>
                </div>
            </div>
            </div>
        </div>
        )
    }
}