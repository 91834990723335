import React, {Component} from 'react';
import SecureLS from 'secure-ls';
import axios from 'axios';
import GLOBAL from '../../../../Global';

export default class WidgetPages extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          pages : [],
        }
      }

    componentDidMount(){
        var ls = new SecureLS({encodingType: 'aes'})

        if(ls.get('identity')){
            this.fetchData()
        }    
    }

    fetchData() {
        var ls = new SecureLS({encodingType: 'aes'})
    
        axios.get(GLOBAL.API_URL + '/api/page/listfollowed/'+ls.get('identity'),{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ pages: response.data });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }
        
    render(){

        const { pages } = this.state;
        const listPages = pages.map((data,i) =>
            <a key={i} className="logo_list" href={'page/'+ data.url} title data-toggle="tooltip" data-original-title={data.name}>
                <img src={data.path_logo} alt={data.name} />
            </a>);

        return(
            <div className="widget">
            <h4 className="widget-title">Pages suivies</h4>
            <div className="users-thumb-list">
                {listPages}
            </div>
        </div>
        )
    }
}