import React, {Component} from 'react';
import SecureLS from 'secure-ls';
import axios from 'axios';
import GLOBAL from '../../../../Global';

export default class WidgetUpdateNews extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          informations : [],
        }
      }

    componentDidMount(){
        var ls = new SecureLS({encodingType: 'aes'})

        if(ls.get('identity')){
            this.fetchData()
        }
    }

    fetchData() {
        var ls = new SecureLS({encodingType: 'aes'})
    
        axios.get(GLOBAL.API_URL + '/api/identity/informations/'+ls.get('identity'),{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ informations: response.data });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }
        
    render(){

        //const { informations } = this.state;

        return(
            <div className="widget">
                <h4 className="widget-title">To read Today</h4>
                <div className="text-center">
                <a style={{marginBottom: '20px'}} href="#rien" title="Voir" data-toggle="tooltip">14 updates <i className="ti-eye" /></a>
                </div>
            </div>
        )
    }
}