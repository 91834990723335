import React, {Component} from 'react';
import LoginForm from './Register/LoginForm';

export default class Login extends Component {

    render(){

        return(
        <div className='container pdng0'>
            <div className="row merged">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                    {/*<div className="land-featurearea">
                        <div className="land-meta">

                            <p>Une plate-forme solciale mondiale pour un réseau mondial.</p>
                            <p>Pourquoi Bothmod ?</p>
                            <p>Aujourd'hui, le monde évolue très rapidement. À mesure que nous avançons, nous souhaitons connecter tout le monde et donner à chacun la possibilité de s'exprimer.</p>
                            <p>Nous vous offrons un endroit où tout le monde peut l'entendre. Nous sommes pour la liberté d'expression.</p>
                        </div>
        </div>*/}
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <div className="login-reg-bg">
                        <div className="log-reg-area sign">

                            <div className="text-center">
                                <img className="member" alt="membre" src="images/member_color.png"/>
                            </div>

                            <LoginForm history={this.props.history}/>

                            <form method="post">

                                <input type="hidden" name="_csrf_token" value="OGOa_xpXsw_BvU2gA5-q1ZWiC-Ak7WIhcGfUB38BzqA" />

                            
                            </form>
                        </div>
                        <div className="log-reg-area reg">

                            <div className="text-center">
                                <img className="member" alt="membre" src="images/member_color.png" />
                            </div>

                            <form method="post">
                                <div className="form-group half">
                                    <input type="text" required="required"/>
                                    <label className="control-label" >Prénom</label><i className="mtrl-select"></i>
                                </div>
                                <div className="form-group half">
                                    <input type="text" required="required"/>
                                    <label className="control-label" >Nom</label><i className="mtrl-select"></i>
                                </div>
                                <div className="form-group">
                                    <input type="text" required="required"/>
                                    <label className="control-label" >Adresse mail</label><i className="mtrl-select"></i>
                                </div>
                                <div className="form-group">
                                    <input type="password" required="required"/>
                                    <label className="control-label" >Mot de passe</label><i className="mtrl-select"></i>
                                </div>

                                <div className="dob">
                                    <label>Date de naissance : </label>
                                    <div className="form-group">
                                        <select >
                                            <option value="Day">Jour</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <option>13</option>
                                            <option>14</option>
                                            <option>15</option>
                                            <option>16</option>
                                            <option>17</option>
                                            <option>18</option>
                                            <option>19</option>
                                            <option>20</option>
                                            <option>21</option>
                                            <option>22</option>
                                            <option>23</option>
                                            <option>24</option>
                                            <option>25</option>
                                            <option>26</option>
                                            <option>27</option>
                                            <option>28</option>
                                            <option>29</option>
                                            <option>30</option>
                                            <option>31</option>
                                        </select>
                                        <div className="chosen-container chosen-container-single" title="">
                                            <a className="chosen-single" href="#rien"><span>Jour</span><div><b></b></div>
                                            </a>
                                            <div className="chosen-drop"><div className="chosen-search"><input type="text"/>
                                            </div><ul className="chosen-results"></ul></div></div>
                                    </div>
                                    <div className="form-group">
                                        <select>
                                            <option value="month">Mois</option>
                                            <option>Janv</option>
                                            <option>Févr</option>
                                            <option>Mars</option>
                                            <option>Avril</option>
                                            <option>Mai</option>
                                            <option>Juin</option>
                                            <option>Juil</option>
                                            <option>Aou</option>
                                            <option>Sept</option>
                                            <option>Oct</option>
                                            <option>Nov</option>
                                            <option>Déc</option>
                                        </select>
                                        <div className="chosen-container chosen-container-single" title="">
                                            <a className="chosen-single" href="#rien"><span>Mois</span>
                                            <div><b></b></div>
                                            </a>
                                            <div className="chosen-drop"><div className="chosen-search"><input type="text"/>
                                            </div><ul className="chosen-results"></ul></div></div>
                                    </div>
                                    <div className="form-group">
                                        <select id="yearSelect">
                                            <option va2ue="year">Année</option>
                                            <option>1905</option>
                                            <option>1906</option>
                                            <option>1907</option>
                                            <option>1908</option>
                                            <option>1909</option>
                                            <option>1910</option>
                                            <option>1911</option>
                                            <option>1912</option>
                                            <option>1913</option>
                                            <option>1914</option>
                                            <option>1915</option>
                                            <option>1916</option>
                                            <option>1917</option>
                                            <option>1918</option>
                                            <option>1919</option>
                                            <option>1920</option>
                                            <option>1921</option>
                                            <option>1922</option>
                                            <option>1923</option>
                                            <option>1924</option>
                                            <option>1925</option>
                                            <option>1926</option>
                                            <option>1927</option>
                                            <option>1928</option>
                                            <option>1929</option>
                                            <option>1930</option>
                                            <option>1931</option>
                                            <option>1932</option>
                                            <option>1933</option>
                                            <option>1934</option>
                                            <option>1935</option>
                                            <option>1936</option>
                                            <option>1937</option>
                                            <option>1938</option>
                                            <option>1939</option>
                                            <option>1940</option>
                                            <option>1941</option>
                                            <option>1942</option>
                                            <option>1943</option>
                                            <option>1944</option>
                                            <option>1945</option>
                                            <option>1946</option>
                                            <option>1947</option>
                                            <option>1948</option>
                                            <option>1949</option>
                                            <option>1950</option>
                                            <option>1951</option>
                                            <option>1952</option>
                                            <option>1953</option>
                                            <option>1954</option>
                                            <option>1955</option>
                                            <option>1956</option>
                                            <option>1957</option>
                                            <option>1958</option>
                                            <option>1959</option>
                                            <option>1960</option>
                                            <option>1961</option>
                                            <option>1962</option>
                                            <option>1963</option>
                                            <option>1964</option>
                                            <option>1965</option>
                                            <option>1966</option>
                                            <option>1967</option>
                                            <option>1968</option>
                                            <option>1969</option>
                                            <option>1970</option>
                                            <option>1971</option>
                                            <option>1972</option>
                                            <option>1973</option>
                                            <option>1974</option>
                                            <option>1975</option>
                                            <option>1976</option>
                                            <option>1977</option>
                                            <option>1978</option>
                                            <option>1979</option>
                                            <option>1980</option>
                                            <option>1981</option>
                                            <option>1982</option>
                                            <option>1983</option>
                                            <option>1984</option>
                                            <option>1985</option>
                                            <option>1986</option>
                                            <option>1987</option>
                                            <option>1988</option>
                                            <option>1989</option>
                                            <option>1990</option>
                                            <option>1991</option>
                                            <option>1992</option>
                                            <option>1993</option>
                                            <option>1994</option>
                                            <option>1995</option>
                                            <option>1996</option>
                                            <option>1997</option>
                                            <option>1998</option>
                                            <option>1999</option>
                                            <option>2000</option>
                                            <option>2001</option>
                                            <option>2002</option>
                                        </select><div className="chosen-container chosen-container-single" title="" id="yearSelect_chosen"><a href="#rien" className="chosen-single"><span>Année</span><div><b></b></div></a><div className="chosen-drop"><div className="chosen-search"><input type="text"/></div><ul className="chosen-results"></ul></div></div>
                                    </div>
                                </div>


                                <div className="form-radio">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" name="radio" /><i className="check-box"></i>Homme      
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" name="radio"/><i className="check-box"></i>Femme
                                        </label>
                                    </div>
                                </div>

                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" /><i className="check-box"></i>Accepter les termes et conditions ?
                                    </label>
                                </div>
                                <a href="#rien" title="" className="already-have">Déjà membre</a>
                                <div className="submit-btns">
                                    <a href="#rien" className="signup" type="button"><span>Créer un compte</span></a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                    {/*<div className="land-featurearea">
                        <div className="land-meta">
                            <p>3 545 895 membres</p>
                            <p>58 pays</p>
                        </div>
    </div>*/}
                </div>
            </div>
        </div>
        )
    }
}