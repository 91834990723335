import React, {Component,Fragment} from 'react';
import SecureLS from 'secure-ls';
import axios from 'axios';
import GLOBAL from '../../../../Global';

export default class TopProfilPic extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          path_img : []
        }

      }

    componentDidMount(){
        var ls = new SecureLS({encodingType: 'aes'})

        if(ls.get('identity')){
            this.fetchData()
        }
    }

    fetchData() {
        var ls = new SecureLS({encodingType: 'aes'})
    
        axios.get(GLOBAL.API_URL + '/api/identity/profilpicture/'+ls.get('identity'),{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ path_img : response.data[0] });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }

    render(){

        const { path_img } = this.state;
        
    return(
        <Fragment>
            <img src={ path_img.path_img } alt='Profil' />
        </Fragment>
        )
    }
}