import React, {Component} from 'react';
import SecureLS from 'secure-ls';
import axios from 'axios';
import GLOBAL from '../../../../../Global';

export default class ImageChannel extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          image : [],
        }
      }

    componentDidMount(){
        this.fetchData()
    }

    fetchData() {
        var ls = new SecureLS({encodingType: 'aes'})

        var params = new URLSearchParams()

        params.append('id_channel', this.props.channel_id)
        params.append('id_user', ls.get('identity'))
    
        axios.get(GLOBAL.API_URL + '/api/channel/profilimage/' + ls.get('identity') + '/' + this.props.channel_id,{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ image: response.data[0] });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }
        
    render(){

        const { image } = this.state;
        /*const listChannels = channels.map((data) =>
            <li className="nav-item"><a className="" href={'#elt'+data.id} data-toggle="tab">{data.name}</a></li>
        );
        const listIdchannels = channels.map((data) =>
                    <ImageChannel props={data.id} />
        </figure>
        );*/

        return(
            <figure className="pic">
                <a href="#rien" title><img src={ image.path_img } alt="" /></a>
            </figure>
        )
    }
}