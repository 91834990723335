import React, {Component} from 'react';
import WidgetUser from '../Index/widget/WidgetUser'
import WidgetUpdateNews from '../Index/widget/WidgetUpdateNews'
import WidgetPages from '../Index/widget/WidgetPages'
import WidgetPostArchives from '../Index/widget/WidgetPostArchives'

export default class ColLeft extends Component {
    render(){
    return(
        <div className="col-lg-2">
        <aside className="sidebar static left">
            <WidgetUser/>
            <WidgetUpdateNews/>
            <WidgetPages/>
            <WidgetPostArchives/>
        </aside>
        </div>
        )
    }
}