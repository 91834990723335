import React, {Component} from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SecureLS from 'secure-ls';
import GLOBAL from '../../Global';
//import nJwt from 'njwt'

export default class LoginForm extends Component {


  constructor(props) {
    super(props)
    this.state = {
      login: "",
      password: "",
      loggedIn: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  fetchData() {

    var props = this.props
    var ls = new SecureLS({encodingType: 'aes'})
    var params = new URLSearchParams()

    params.append('login', this.state.login)
    params.append('password', this.state.password)

    axios.post(GLOBAL.API_URL + '/api/authentication/login', params,{
      auth: {
        username: GLOBAL.USRNAME,
        password: GLOBAL.PASSWD
      },
        headers: {
          'X-API-KEY': 'CODEX@123',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*'
        }
    })
    .then(function (response) {
        const Success = response.data
        //console.log('token :' + Success.data.token)

        /* TODO TOKEN
        
        var decoded = nJwt.verify(Success.data.token, 'C0C0RiKa?29410', 'HS512');
        console.log(decoded)

        console.log("Decoded token : " + decoded.id)*/

        ls.set('identity',Success.data.id)
        props.history.push('/')
    })
    .catch(function (error) {
      const stringResult = error.response.data
      toast.info(stringResult, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        ls.removeAll();
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });  
  }

  onSubmit = (e) =>{
    e.preventDefault();
    this.fetchData();
    this.setState({
         password: ""
        });
    };

    render() {

      return (
        <form onSubmit={e => this.onSubmit(e)}>
        <ToastContainer 
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover/>

         <div className="form-group">
            <input
              id="inputEmail"
              name="login"
              value={this.state.login}
              onChange={this.handleChange}
              type="text"/>
              <label className="control-label">Email ou numéro de téléphone :</label>
            <i className="mtrl-select"></i>
          </div>
          <div className="form-group">
            <input
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              type="password"/>
              <label className="control-label">Mot de passe :</label>
              <i className="mtrl-select"></i>
          </div>

          <div className="checkbox">
              <label>
                  <input type="checkbox" defaultChecked/><i className="check-box"></i>Se souvenir de moi.
              </label>
          </div>
          <a href='#rien' title="" className="forgot-pwd">Mot de passe oublié?</a>
          <div className="submit-btns">
              <button onClick={e => this.onSubmit(e)} className="signin" type="submit"><span>Se connecter</span></button> ou <a href="#rien" className="signup" type="button"><span>Créer un compte</span></a>
          </div>
      </form>
      );
    }
  }