module.exports = {

    //BASIC AUTH
    USRNAME: 'bm_api_us3r',
    PASSWD: '!^BunK3r_Pl4C3',
    
    //UNUSED
    STORE_KEY: 'a56z0fzrNpl^2',
    BASE_URL: 'http://someurl.com',
    API_URL: 'https://api.bothmod.com',
    
    COLOR: {
      ORANGE: '#C50',
      DARKBLUE: '#0F3274',
      LIGHTBLUE: '#6EA8DA',
      DARKGRAY: '#999',
    },
  };