import React, {Component,Fragment} from 'react'
import Header from './../components/Header'
import Language from './../components/Language'
import Login from './../components/Login'
import Bottom from './../components/Bottom'

import SecureLS from 'secure-ls'

export default class LoginRegister extends Component {
    render(){

        var ls = new SecureLS({encodingType: 'aes'})
        if(ls.get('identity')){
            this.props.history.push('/')
        }

        return(
            <Fragment>
                <Header />
                <Language />
                <Login history={this.props.history} />
                <Bottom/>
             </Fragment>
        );
    }
}
