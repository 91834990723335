import React, {Component} from 'react';
import axios from 'axios';
import GLOBAL from '../../../../../Global';
import ImageChannel from '../WidgetUser/ImageChannel'

export default class ChannelList extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          channels : [],
        }
      }

    truncate(str){
        return str.substring(0, 3) + ".";
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData() {
    
        axios.get(GLOBAL.API_URL + '/api/channel/list/',{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ channels: response.data });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }
        
    render(){

        const { channels } = this.state;
        const listChannels = channels.map((data,i) =>
            <li className="nav-item" key={i}><a className="" href={'#elt'+data.id} data-toggle="tab"><span>{this.truncate(data.name)}</span></a></li>
        );
        const listIdchannels = channels.map((data,i) =>
            <ImageChannel key={i} channel_id={data.id} />
        );

        return(
            <div className="your-page">

                <ul className="nav nav-tabs likes-btn">
                    {listChannels}
                </ul>

                <div className="text-center">

                    {/*<figure className="pic">
                    <a href="#" title><img src="images/user/perso.JPG" alt="" /></a>
                    </figure>
                    <figure className="pic">
                    <a href="#" title><img src="images/user/pro.JPG" alt="" /></a>
                    </figure>
                    <figure className="pic">
                    <a href="#" title><img src="images/user/asso.JPG" alt="" /></a>
        </figure>*/}
                    {listIdchannels}
                    
                    <div className="tab-content">
                    <div className="tab-pane fade show " id="elt1">
                        test channel 1
                    </div>
                    <div className="tab-pane fade show " id="elt2">
                        test channel 2
                    </div>
                    <div className="tab-pane fade show " id="elt3">
                        test channel 3
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}