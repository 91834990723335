import React, {Component} from 'react';

export default class ColRight extends Component {
    render(){
        return(
            <div className="col-lg-2">
                <aside className="sidebar static right">
                <div className="widget">
                    <div className="new-postbox">
                    <figure>
                        <img src="protected/images/marc_perso.jpg" alt="" />
                    </figure>
                    <div className="newpst-input">
                        <form method="post">
                        <textarea rows={2} placeholder="write something" defaultValue={""} />
                        <div className="attachments">
                            <ul>
                            <li>
                                <i className="fa fa-music" />
                                <label className="fileContainer">
                                <input type="file" />
                                </label>
                            </li>
                            <li>
                                <i className="fa fa-image" />
                                <label className="fileContainer">
                                <input type="file" />
                                </label>
                            </li>
                            <li>
                                <i className="fa fa-video-camera" />
                                <label className="fileContainer">
                                <input type="file" />
                                </label>
                            </li>
                            <li>
                                <i className="fa fa-camera" />
                                <label className="fileContainer">
                                <input type="file" />
                                </label>
                            </li>
                            <li>
                                <button type="submit">Post</button>
                            </li>
                            </ul>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                <div className="widget">
                    <h4 className="widget-title">Friends Activity</h4>
                    <ul className="activitiez">
                    <li>
                        <div className="activity-meta">
                        <i>10 hours Ago</i>
                        <span><a href="#" title>Commented on Video posted </a></span>
                        <h6>by <a href="time-line.html">black demon.</a></h6>
                        </div>
                    </li>
                    <li>
                        <div className="activity-meta">
                        <i>30 Days Ago</i>
                        <span><a href="#" title>Posted your status. “Hello guys, how are you?”</a></span>
                        </div>
                    </li>
                    <li>
                        <div className="activity-meta">
                        <i>2 Years Ago</i>
                        <span><a href="#" title>Share a video on her timeline.</a></span>
                        <h6>"<a href="#">you are so funny mr.been.</a>"</h6>
                        </div>
                    </li>
                    </ul>
                </div>{/* recent activites */}
                <div className="widget">
                    <div className="banner medium-opacity bluesh">
                    <div className="bg-image" style={{backgroundImage: 'url(images/resources/baner-widgetbg.jpg)'}} />
                    <div className="baner-top">
                        <span><img alt="" src="images/book-icon.png" /></span>
                        <i className="fa fa-ellipsis-h" />
                    </div>
                    <div className="banermeta">
                        <p>
                        create your own favourit page.
                        </p>
                        <span>like them all</span>
                        <a data-ripple title href="#">start now!</a>
                    </div>
                    </div>											
                </div>
                </aside>
            </div>
        )
    }
}