import React, {Component} from 'react';
import SecureLS from 'secure-ls';
import axios from 'axios';
import GLOBAL from '../../../../Global';
import ChannelList from '../widget/WidgetUser/ChannelList'

export default class ColLeft extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          informations : [],
          network : []
        }
      }


    componentDidMount(){
        var ls = new SecureLS({encodingType: 'aes'})

        if(ls.get('identity')){
            this.fetchDataInotrmations()
            this.fetchDataNetwork()
        }
    }

    fetchDataInotrmations() {
        var ls = new SecureLS({encodingType: 'aes'})
    
        axios.get(GLOBAL.API_URL + '/api/identity/informations/'+ls.get('identity'),{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ informations: response.data });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }
     
    fetchDataNetwork() {
        var ls = new SecureLS({encodingType: 'aes'})
    
        axios.get(GLOBAL.API_URL + '/api/friend/countfriend/'+ls.get('identity'),{
        auth: {
            username: GLOBAL.USRNAME,
            password: GLOBAL.PASSWD
        },
            headers: {
            'X-API-KEY': 'CODEX@123',
            'Access-Control-Allow-Origin': '*'
            }
        })
        .then(
            (response) => {
                this.setState({ network: response.data[0] });
            }
        )
        .catch(function (error) {
            console.error(error)
        })
    }

    render(){

        const { informations } = this.state;
        const { network } = this.state;

        return(
            <div className="widget">
            <h4 className="widget-title">
            {informations.surname} {informations.name} 
            </h4>
                <ChannelList />
            <div className="page-likes">
                {/* Tab panes */}
                <div className="tab-content">
                <div className="tab-pane active fade show " id="link1">
                <span>{network.cpt_friend} contacts <i className="ti-settings" /></span>
                </div>
                <div className="tab-pane fade" id="link2">
                    <span><i className="ti-eye" />440</span>
                    <a href="#rien" title="weekly-likes">440 new views this week</a>
                    <div className="users-thumb-list">
                    <a href="#rien" title="Anderw" data-toggle="tooltip">
                        <img src="images/resources/userlist-1.jpg" alt="" />
                    </a>
                    <a href="#rien" title="frank" data-toggle="tooltip">
                        <img src="images/resources/userlist-2.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Sara" data-toggle="tooltip">
                        <img src="images/resources/userlist-3.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Amy" data-toggle="tooltip">
                        <img src="images/resources/userlist-4.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Ema" data-toggle="tooltip">
                        <img src="images/resources/userlist-5.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Sophie" data-toggle="tooltip">
                        <img src="images/resources/userlist-6.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Maria" data-toggle="tooltip">
                        <img src="images/resources/userlist-7.jpg" alt="" />
                    </a>
                    </div>
                </div>
                <div className="tab-pane fade" id="link3">
                    <span><i className="ti-eye" />333</span>
                    <a href="#rien" title="weekly-likes">333 new views this week</a>
                    <div className="users-thumb-list">
                    <a href="#rien" title="Anderw" data-toggle="tooltip">
                        <img src="images/resources/userlist-1.jpg" alt="" />
                    </a>
                    <a href="#rien" title="frank" data-toggle="tooltip">
                        <img src="images/resources/userlist-2.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Sara" data-toggle="tooltip">
                        <img src="images/resources/userlist-3.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Amy" data-toggle="tooltip">
                        <img src="images/resources/userlist-4.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Ema" data-toggle="tooltip">
                        <img src="images/resources/userlist-5.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Sophie" data-toggle="tooltip">
                        <img src="images/resources/userlist-6.jpg" alt="" />
                    </a>
                    <a href="#rien" title="Maria" data-toggle="tooltip">
                        <img src="images/resources/userlist-7.jpg" alt="" />
                    </a>
                    </div>
                </div>
                </div>
            </div>
            </div>
        )
        }
}
